@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p {
    @apply text-base leading-normal 2xl:text-lg;
  }
  h1 {
    @apply text-4xl 2xl:text-5xl;
  }

  h2 {
    @apply text-2xl 2xl:text-3xl;
  }

  h3 {
    @apply text-xl 2xl:text-2xl;
  }

  h4 {
    @apply text-lg 2xl:text-xl;
  }
}

@import './_normalize.scss';
@import './_common.scss';
@import './_typography.scss';
@import './_button.scss';
